@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

.games-container {
    --primary-color: #8A2BE2;
    --secondary-color: #4A0E82;
    --background-color: #090A0F;
    --text-color: #FFFFFF;
    --glow-color: rgba(138, 43, 226, 0.7);
    --locked-color: #555;
    --coming-soon-color: #FFD700;
    --play-now-color: #00FF00;
    --free-color: #4CAF50;
    --paid-color: #FFA500;

    font-family: 'Poppins', sans-serif;
    background: var(--background-color);
    color: var(--text-color);
    margin: 0;
    padding: 20px;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 70px;
}

.game-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.game-item {
    background: linear-gradient(135deg, var(--secondary-color), var(--background-color));
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 280px;
    cursor: pointer;
}

.game-link {
    text-decoration: none;
    color: inherit;
}

.game-item:hover, .game-link:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}


.game-preview-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 10px;
}

.game-preview {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease;
    max-height: 160px;
}

.game-title {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.game-description {
    font-size: 0.75em;
    opacity: 0.8;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.game-price {
    font-size: 0.6em;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    text-decoration: none;
    background-color: #8A2BE2;
    color: #FFFFFF;
}

.game-price svg {
    font-size: 1.8em;
    margin-right: 5px;
}

.game-price:hover {
    background-color: #7B27CC;
}

.game-price.playable {
    font-size: 1.2em;
    background: linear-gradient(90deg, #4CAF50, #45a049, #2E7D32, #4CAF50);
    background-size: 300% 100%;
    animation: gradient 5s ease infinite;
    transition: all 0.3s ease;
}

.game-price.playable:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(76, 175, 80, 0.7);
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

#partner-logo {
    width: 100px;
    height: auto;
    opacity: 0.7;
    transition: opacity 0.3s ease, transform 0.3s ease;
    margin: 20px auto;
    display: block;
}

#partner-logo:hover {
    opacity: 1;
    transform: scale(1.1);
}

.game-item.view-all {
    grid-column: span 2;
    background: linear-gradient(rgba(138, 43, 226, 0.7), rgba(74, 14, 130, 0.7)), url('https://images.wowcube.com/image/image/project/cover/630f24c171c649e1373a3272.webp');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.game-item.view-all .game-title {
    display: none;
}

.view-all-link {
    font-size: 1.5em;
    padding: 15px 30px;
    background-color: rgba(255, 255, 255, 0.9);
    color: var(--primary-color);
    border-radius: 25px;
    text-decoration: none;
    font-weight: bold;
    transition: all 0.3s ease;
}

.view-all-link:hover {
    background-color: var(--primary-color);
    color: var(--text-color);
}

.iframe-container {
  width: 100%;
  height: 100vh;
  position: relative;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.game-locked {
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.game-locked:hover {
    opacity: 1;
}

.game-price.locked {
    background: linear-gradient(180deg, #4CAF50, #45a049, #2E7D32, #4CAF50);
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.game-price.locked:hover {
    background-color: #777;
}

.lock-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(83, 157, 73, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
}

.game-locked:hover .lock-overlay {
    /*background-color: rgba(0, 0, 0, 0.4);*/
}

.lock-icon {
    font-size: 4rem;
    color: #e6fdd9;
}