@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
    --primary-color: #8A2BE2;
    --primary-color2: #b56cfa;
    --secondary-color: #4A0E82;
    --background-color: #000000;
    --text-color: #FFFFFF;
    --completed-color: #33e52c;
    --glow-color: rgba(138, 43, 226, 0.7);
    --reward-color: #33e52c;
    --presale-color: #FF69B4;
}

* {
    text-decoration: none;
}

body, html {
    font-family: 'Poppins', sans-serif;
    background: #05060f;
    color: var(--text-color);
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}

.wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #090A0F;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
}

.model-viewer {
    width: 100%;
    height: 100%;
    border: none;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(9, 10, 15, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 0.5s ease-out;
}

.loading-overlay.hidden {
    opacity: 0;
    pointer-events: none;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #8A2BE2;
    border-top: 5px solid #4A0E82;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.content {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10;
}


.screen {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 75vh;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    width: 90%;
    max-width: 400px;
    background: rgba(138, 43, 226, 0.15);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
    display: flex;
    flex-direction: column;
}

.screen.active {
    display: flex;
    margin-bottom: 20px;
}

.tab-container {
    display: flex;
    justify-content: space-around;
    background: rgba(255, 255, 255, 0.1);
    padding: 10px 0;
    /*margin-bottom: 20px;*/
}

.tab-sub-container {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 30px;
    padding: 5px;
    display: inline-flex;
    position: relative;
    margin: 15px auto;
}

.tab-sub-container .tab {
    color: var(--text-color);
    background: none;
    border: none;
    padding: 10px 20px;
    font-size: 0.9em;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    z-index: 1;
}

.tab-sub-container .slider {
    position: absolute;
    height: calc(100% - 10px);
    top: 5px;
    left: 5px;
    right: 5px;
    background: linear-gradient(135deg, #8a2be2, #4b0082);
    border-radius: 25px;
    transition: all 0.3s ease;
}

.tab-sub-container .tab.active {
    color: white;
}

.tab {
    color: var(--text-color);
    background: none;
    border: none;
    padding: 10px 15px;
    font-size: 0.9em;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.tab.active {
    background: var(--primary-color);
    box-shadow: 0 0 15px var(--glow-color);
}

.leaders, .friends  {
    padding-top: 40px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    overflow-y: auto;
    flex-grow: 1;
}

.store {
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    overflow-y: auto;
    flex-grow: 1;
}

.quests {
    /*padding: 20px;*/
    overflow-y: auto;
    flex-grow: 1;
}

.quest-item.completed {
    background-color: #90EE90; /* Серый фон для выполненных миссий */
    color: #a9a9a9; /* Более светлый цвет текста */
    cursor: not-allowed; /* Курсор указывает, что элемент неактивен */
}

.quest-item.completed span {
    color: #ffffff;
}

.quest-status {
    font-size: 0.8em;
    color: #28a745; /* Зеленый цвет для статуса выполненной миссии */
    margin-left: auto;
}


.quests-title, .friends-title, .store-title {
    font-size: 1.3em;
    font-weight: 700;
    color: #FFFFFF;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.leaders-title {
    font-size: 1.3em;
    font-weight: 700;
    color: #FFFFFF;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    margin-bottom: 20px;
    margin-top: -20px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.leader-item, .quest-item, .friend-item, .store-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    margin-bottom: 10px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.leader-item:hover, .friend-item:hover, .store-item:hover {
    background: rgba(255, 255, 255, 0.12);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.quest-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.player-info, .quest-info, .friend-info, .store-info {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0; /* Это важно для работы text-overflow */
}

.player-rank, .quest-icon, .friend-icon, .store-icon {
    font-size: 1.1em;
    font-weight: 700;
    margin-right: 12px;
    color: var(--reward-color);
    min-width: 25px;
    text-align: center;
    text-shadow: 0 0 5px rgb(0 255 47 / 50%);
}

.player-avatar, .friend-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 12px;
    background-size: cover;
    background-position: center;
    border: 2px solid var(--primary-color);
    box-shadow: 0 0 10px var(--glow-color);
}

.player-name, .quest-name, .friend-name, .store-name {
    font-size: 1em;
    font-weight: 600;
}

.player-name {
    font-size: 1em;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100%);
}

.quest-reward, .friend-level, .store-price {
    font-size: 0.9em;
    font-weight: 700;
    color: var(--reward-color);
    text-shadow: 0 0 5px rgb(40 255 0 / 50%);
    background: rgba(255, 215, 0, 0.1);
    padding: 5px 10px;
    border-radius: 10px;
}

.player-score {
    font-size: 0.9em;
    font-weight: 700;
    color: var(--reward-color);
    text-shadow: 0 0 5px rgb(40 255 0 / 50%);
    background: rgba(255, 215, 0, 0.1);
    padding: 5px 10px;
    border-radius: 10px;
    margin-left: 10px;
    flex-shrink: 0; /* Предотвращает сжатие счета */
}

/* Стили для скроллбара */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, var(--secondary-color), var(--secondary-color));
    border-radius: 30px;
    border: 2px solid rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(45deg, var(--secondary-color), var(--primary-color));
}

/* Применяем стили скроллбара ко всем контейнерам с прокруткой */
.screen,
.leaders,
.quests,
.friends,
.store,
.friends-content,
.friends-list-content {
    scrollbar-width: thin;
    scrollbar-color: #392a47 #ffffff00;
}

/* Для Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #392a47 #ffffff00;
}

#renderCanvas {
    width: 100%;
    height: 100%;
    touch-action: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(5, 6, 15, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    z-index: 1000;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.2);
}

.nav-button {
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 0.8em;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 8px 12px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    max-width: 80px;
    position: relative;
    overflow: hidden;
}

.nav-button:hover {
    color: var(--primary-color2);
}

.nav-button.active {
    color: var(--primary-color2);
}

.nav-button.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    box-shadow: 0 0 10px var(--glow-color);
}

.nav-icon {
    font-size: 1.2em;
    margin-bottom: 3px;
}

.play-button {
    background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
    color: var(--text-color);
    font-size: 1em;
    font-weight: 700;
    padding: 12px 20px;
    border-radius: 30px;
    border: none;
    box-shadow: 0 0 20px var(--glow-color);
    transition: all 0.3s ease;
    transform: scale(1.1);
    max-width: 120px;
}

.play-button:hover, .play-button.active {
    background: linear-gradient(45deg, #b44dff, #6a1cb1);
    box-shadow: 0 0 30px #b44dff;
    transform: scale(1.15);
    color: var(--text-color);
}

#playScreen {
    background: linear-gradient(135deg, var(--background-color), var(--secondary-color));
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    scrollbar-width: none;
}

#playScreen h2 {
    font-size: 1.8em;
    margin-bottom: 5px;
    padding-right: 20px;
    padding-left: 20px;
}

#playScreen p {
    font-size: 1em;
    margin-bottom: 20px;
}

.quest-item {
    text-align: left;
    padding: 15px;
    margin: 10px 15px;
}

.quest-icon {
    font-size: 1.5em;
    margin-right: 10px;
}

.quest-name {
    flex-grow: 1;
}

.quest-reward {
    background: rgb(21 255 0 / 20%);
    padding: 5px 10px;
    border-radius: 15px;
    font-weight: 600;
}

.quest-item.completed {
    background-color: var(--primary-color);
    color: white;
}

.quest-icon.completed,
.quest-name.completed {
    color: white;
}

.quest-completed-icon {
    font-size: 1.5em;
    color: #ffffff;
}

#wowcube-image-container {
    position: relative;
    width: 80%;
    max-width: 300px;
    margin: 20px 0;
}

#wowcube-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

#wowcube-title {
    font-size: 2.5em;
    font-weight: 700;
    color: #FFFFFF;
    text-shadow: 0 0 10px var(--glow-color);
    margin-bottom: 20px;
    letter-spacing: 2px;
}

.presale-button {
    background: linear-gradient(45deg, var(--presale-color), #FF1493);
    color: var(--text-color);
    font-size: 1.2em;
    font-weight: 700;
    padding: 15px 30px;
    border-radius: 30px;
    border: none;
    box-shadow: 0 0 20px rgba(255, 105, 180, 0.7);
    transition: all 0.3s ease;
    animation: bounce 1s infinite;
    cursor: pointer;
    position: absolute;
    /*bottom: -60px;*/
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    /*text-decoration: underline;*/
}

.presale-button:hover {
    background: linear-gradient(45deg, #FF1493, var(--presale-color));
    box-shadow: 0 0 30px rgba(255, 105, 180, 0.9);
}

@keyframes bounce {
    0%, 100% {
        transform: translateX(-50%) translateY(0);
    }
    50% {
        transform: translateX(-50%) translateY(-10px);
    }
}

.user-info-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(5, 6, 15, 0.99);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    z-index: 1000;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
}

.user-level, .user-balance {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    font-weight: 600;
}

.user-level svg, .user-balance svg {
    margin-right: 5px;
    font-size: 1.2em;
}

.user-level {
    color: var(--completed-color);
}

.user-balance {
    color: var(--reward-color);
    margin-left: auto;
}

.quest-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    top: 50px;
    background: rgb(0 0 0 / 50%);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    z-index: 2000;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 0 -10px 30px rgba(0, 0, 0, 0.3);
    transform: translateY(100%);
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
    overflow-y: auto;
}

.quest-popup.active {
    transform: translateY(0);
}

.quest-popup-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 1.5em;
    cursor: pointer;
    transition: all 0.3s ease;
}

.quest-popup-close:hover {
    color: var(--primary-color);
    transform: rotate(90deg);
}

.padding-container {
    width: 100%;
    min-height: 100px;
}

.quest-popup-image {
    width: 200px;
    height: 200px;
    /*border-radius: 50%;*/
    /*margin-bottom: 20px;*/
    /*border: 3px solid var(--primary-color);*/
    /*box-shadow: 0 0 20px var(--glow-color);*/
    background-size: contain;;
    background-position: center;
    background-repeat: no-repeat;
}

.quest-popup-image svg {
    width: 50px;
    height: 50px;
}

.quest-popup-title {
    font-size: 1.8em;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 0 10px var(--glow-color);
}

.quest-popup-input {
    width: 40%;
    max-width: 300px;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    font-size: 16px;
    transition: all 0.3s ease;
}

.quest-popup {
    -webkit-overflow-scrolling: touch;
}

@supports (-webkit-touch-callout: none) {
    .quest-popup {
        padding-bottom: 70px; /* Дополнительный отступ для iOS */
    }
}

.quest-popup-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 10px rgba(138, 43, 226, 0.5);
}

.quest-popup-reward {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 20px;
    color: #55e251;
    background: rgb(40 255 0 / 10%);
    padding: 5px 15px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgb(81 255 0 / 30%);
}

.quest-popup-description {
    text-align: center;
    margin-bottom: 30px;
    line-height: 1.6;
    color: #B0B0B0;
}

.quest-popup-cta {
    background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
    color: var(--text-color);
    font-size: 1.1em;
    font-weight: 700;
    padding: 12px 30px;
    border-radius: 30px;
    border: none;
    box-shadow: 0 0 20px var(--glow-color);
    transition: all 0.3s ease;
    cursor: pointer;
    margin-bottom: 15px;
}

.quest-popup-cta:hover {
    background: linear-gradient(45deg, #b44dff, #6a1cb1);
    box-shadow: 0 0 30px #b44dff;
    transform: translateY(-2px);
}

.quest-popup-status {
    background: none;
    border: 1px solid var(--text-color);
    color: var(--text-color);
    font-size: 0.9em;
    font-weight: 600;
    padding: 8px 20px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.quest-popup-status:hover {
    background: rgba(255, 255, 255, 0.1);
    border-color: var(--primary-color);
    color: var(--primary-color);
}

.settings-icon {
    color: var(--text-color);
    font-size: 1.5em;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.settings-icon:hover {
    color: var(--primary-color);
    transform: rotate(90deg);
}

@media (max-width: 480px) {

    .tab {
        font-size: 0.8em;
        padding: 8px 12px;
    }

    .leaders-title, .quests-title, .friends-title, .store-title {
        font-size: 1.2em;
    }

    .player-rank, .quest-icon, .friend-icon, .store-icon {
        font-size: 1em;
        min-width: 20px;
    }

    .player-avatar, .friend-avatar {
        width: 30px;
        height: 30px;
    }

    .player-name, .player-score, .quest-name, .friend-name, .friend-level, .store-name, .store-price {
        font-size: 0.9em;
    }

    .quest-reward {
        font-size: 0.7em;
    }

    .nav-button {
        font-size: 0.7em;
        padding: 6px 10px;
    }

    .play-button {
        font-size: 0.9em;
        padding: 10px 16px;
    }

    #wowcube-title {
        font-size: 2em;
    }

    .presale-button {
        font-size: 1em;
        padding: 12px 20px;
    }

    .user-info-header {
        padding: 8px 15px;
    }

    .user-level, .user-balance {
        font-size: 0.8em;
    }

    .user-balance {
        max-width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 10px;
    }

    .quest-popup {
        padding: 20px;
    }

    .quest-popup-image {
        width: 300px;
        height: 300px;
    }

    .quest-popup-title {
        font-size: 1.5em;
    }

    .quest-popup-reward {
        font-size: 1em;
    }

    .quest-popup-cta {
        font-size: 1em;
        padding: 10px 25px;
    }

    .quest-popup-status {
        font-size: 0.8em;
        padding: 6px 15px;
    }

    .settings-icon {
        font-size: 1.3em;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

h1, p, .quest-popup {
    opacity: 0;
    transform: translateY(100%);
    animation: fadeInUp 0.3s ease-out forwards;
}

.fade-in h1, .fade-in p, .fade-in .screen-image {
    animation-delay: 0.3s;
}

.fade-in p {
    animation-delay: 0.5s;
}

.fade-in .screen-image {
    animation-delay: 0.7s;
}


.friends-content {
    height: calc(100% - 60px);
    overflow-y: auto;
    padding: 20px;
}

.friends-list-content {
    height: calc(100% - 60px);
    overflow-y: auto;
}

.friends-content.active {
    display: block;
}

.friends-progress {
    margin-bottom: 20px;
}

.friends-progress h3 {
    text-align: center;
    margin-bottom: 15px;
}

.progress-bar {
    width: 100%;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    overflow: hidden;
    position: relative;
}

.progress-fill {
    height: 100%;
    background-color: var(--primary-color, #8A2BE2);
    width: 0%;
    transition: width 0.5s ease-in-out;
    position: absolute;
    left: 0;
    top: 0;
}

.progress-info {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    color: var(--text-color, white);
    z-index: 1;
}

.friends-rewards {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    gap: 10px;
}

.reward-card {
    flex-basis: calc(50% - 10px);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding-top: 15px;
    padding-right: 10px;
    padding-bottom: 15px;
    padding-left: 10px;

    text-align: center;
    transition: all 0.3s ease;
    margin-bottom: 20px;
}

.reward-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.reward-card i {
    font-size: 2em;
    color: var(--reward-color);
    margin-bottom: 10px;
}

.reward-card p {
    font-size: 0.9em;
    margin-bottom: 5px;
}

.reward-card .reward-amount {
    font-size: 1.2em;
    font-weight: bold;
    color: var(--reward-color);
}

.reward-card.premium {
    background: linear-gradient(45deg, #6b3faf, #4a0e82);
}

.reward-card.premium i {
    color: #ffd700;
}

.friends-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.friends-action-btn {
    background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
    color: var(--text-color);
    border: none;
    padding: 12px 24px;
    border-radius: 25px;
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(138, 43, 226, 0.4);
    margin-bottom: 15px;
    width: 100%;
    max-width: 300px;
}

.friends-action-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(138, 43, 226, 0.6);
}

.friends-action-btn:active {
    transform: translateY(1px);
    box-shadow: 0 2px 10px rgba(138, 43, 226, 0.4);
}

.friends-action-btn i {
    margin-right: 8px;
}

.share-dad-btn {
    background: linear-gradient(45deg, #FF6B6B, #4ECDC4);
    font-size: 1.1em;
    padding: 15px 30px;
    margin-top: 10px;
    animation: pulse 1.5s infinite;
    width: 100%;
    max-width: 300px;
}

.share-dad-btn:hover {
    background: linear-gradient(45deg, #FF8E8E, #6BDED6);
    animation: none;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 107, 107, 0.7);
    }
    70% {
        transform: scale(1.05);
        box-shadow: 0 0 0 10px rgba(255, 107, 107, 0);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 107, 107, 0);
    }
}

@media (max-width: 480px) {
    .reward-card {
        margin: 10px 0;
    }
}

.card-header svg {
    height: 2em;
}

.buy-wowcube-button {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(45deg, #ff00ff, #00ffff);
    border: none;
    border-radius: 50px;
    color: white;
    font-size: 24px;
    font-weight: bold;
    padding: 15px 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 0 20px rgba(255, 0, 255, 0.5);
    text-transform: uppercase;
    letter-spacing: 2px;
}

.buy-wowcube-button:hover {
    transform: translateX(-50%) scale(1.05);
    box-shadow: 0 0 30px rgba(255, 0, 255, 0.7);
}

.buy-wowcube-button:active {
    transform: translateX(-50%) scale(0.95);
}

.buy-wowcube-button .fa-shopping-cart {
    margin-right: 10px;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 20px rgba(255, 0, 255, 0.5);
    }
    50% {
        box-shadow: 0 0 30px rgba(0, 255, 255, 0.7);
    }
    100% {
        box-shadow: 0 0 20px rgba(255, 0, 255, 0.5);
    }
}

.buy-wowcube-button {
    animation: pulse 2s infinite;
}

.user-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.user-avatar {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-top: 10px;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.5);
}

.balance-item {
    display: flex;
    align-items: center;
    background: linear-gradient(45deg, #1a0033, #3a015c);
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 20px;
    box-shadow: 0 0 20px rgba(106, 13, 173, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
}

.balance-item:hover {
    box-shadow: 0 0 25px rgba(106, 13, 173, 0.6);
    transform: translateY(-2px);
}

.balance-icon {
    font-size: 28px;
    color: var(--reward-color);
    margin-right: 15px;
    text-shadow: 0 0 10px rgba(179, 157, 219, 0.7);
}

.balance-label {
    font-size: 16px;
    font-weight: 600;
    color: var(--reward-color);
    margin-right: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.balance-amount {
    font-size: 20px;
    font-weight: bold;
    color: var(--reward-color);
    text-shadow: 0 0 10px rgba(124, 77, 255, 0.7);
    margin-left: auto;
    padding: 5px 10px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border: 1px solid rgba(124, 77, 255, 0.3);
}

.connect-wallet-button {
    display: block;
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 18px;
    color: #ffffff;
    background: linear-gradient(45deg, #ff00ff, #00ffff);
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}

.connect-wallet-button:hover {
    transform: scale(1.05);
    /*box-shadow: 0 0 15px rgba(255, 0, 255, 0.5);*/
}

.connect-wallet-button svg {
    margin-right: 10px;
}

.vibration-toggle {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.vibration-toggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.vibration-toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2c3e50;
    transition: .4s;
    border-radius: 34px;
}

.vibration-toggle-slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

input:checked + .vibration-toggle-slider {
    background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
}

input:checked + .vibration-toggle-slider:before {
    transform: translateX(26px);
}

.vibration-toggle-slider:after {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 14px;
    color: white;
}

input:checked + .vibration-toggle-slider:after {
    left: unset;
    right: 10px;
}

.vibration-toggle:hover .vibration-toggle-slider:before {
    box-shadow: 0 0 8px var(--glow-color);
}


.leader-item.skeleton {
    opacity: 0.7;
}

.skeleton-text {
    height: 1em;
    width: 150px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 4px;
}

.skeleton-circle {
    border-radius: 50%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
}

.skeleton-rank {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    margin-right: 10px;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

.quest-item.skeleton {
    opacity: 0.7;
}

.quest-icon.skeleton-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
}

.quest-name.skeleton-text, .quest-reward.skeleton-text {
    height: 1em;
    width: 150px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 4px;
}

.quest-reward.skeleton-text {
    width: 80px;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}