.quest-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 50px);
    background: rgb(0 0 0 / 50%);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    z-index: 4000;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 0 -10px 30px rgba(0, 0, 0, 0.3);
    transform: translateY(100%);
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
    overflow-y: auto;
}

.quest-popup.active {
    transform: translateY(0);
}

.quest-popup-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 1.5em;
    cursor: pointer;
    transition: all 0.3s ease;
}

.quest-popup-close:hover {
    color: var(--primary-color);
    transform: rotate(90deg);
}

.padding-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.quest-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.quest-popup-header {
    font-size: 10vw;
    font-weight: 700;
    color: #FFFFFF;
    text-align: center;
    letter-spacing: 2px;
    line-height: 1.2;
    margin-bottom: 20px;
}

.quest-popup-header span {
    display: block;
}

.highlight {
    font-size: 16vw;
    color: #ffffff;
    text-shadow: 0 0 5px #FFD700,
    0 0 10px #FFD700,
    0 0 20px #FFD700,
    0 0 40px #FFD700;
    animation: glow 2s ease-in-out infinite alternate;
}

@keyframes glow {
    from {
        text-shadow: 0 0 5px #FFD700,
        0 0 10px #FFD700,
        0 0 20px #FFD700,
        0 0 40px #FFD700;
    }
    to {
        text-shadow: 0 0 10px #FFD700,
        0 0 20px #FFD700,
        0 0 40px #FFD700,
        0 0 80px #FFD700;
    }
}

.quest-popup-image-container {
    position: relative;
    width: 80%;
    margin-bottom: 20px;
}

.quest-popup-image {
    width: 100%;
    height: auto;
    object-fit: contain;
    animation: float 4s ease-in-out infinite;
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
    }
}

.quest-popup-badge {
    position: absolute;
    bottom: -8%;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
    padding: 1.5vh 4vw;
    border-radius: 30px;
    font-weight: 700;
    font-size: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
    white-space: nowrap;
    min-width: 280px;
}

.money-icon {
    width: 7vw;
    height: 7vw;
    margin-left: 12px;
}

.quest-popup-description {
    font-size: 3.5vw;
    width: 85%;
    text-align: center;
    line-height: 1.6;
    margin-bottom: 30px;
}

.quest-popup-cta {
    background: linear-gradient(45deg, #8A2BE2, #9400D3, #8A2BE2);
    background-size: 200% 200%;
    border: none;
    padding: 1.5vh 6vw;
    font-size: 6.5vw;
    font-weight: 700;
    color: var(--text-color);
    border-radius: 35px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 20px rgba(138, 43, 226, 0.5);
    animation: shimmer 3s linear infinite, pulse 2s infinite;
    width: 90%;
    position: relative;
    overflow: hidden;
}

.quest-popup-cta::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 50%,
            rgba(255, 255, 255, 0) 100%
    );
    transform: rotate(45deg);
    animation: moveSparkle 3s ease-in-out infinite;
}

.quest-popup-cta:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 25px rgba(138, 43, 226, 0.7);
}

@keyframes shimmer {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(138, 43, 226, 0.4);
    }
    70% {
        box-shadow: 0 0 0 15px rgba(138, 43, 226, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(138, 43, 226, 0);
    }
}

@keyframes moveSparkle {
    0%, 100% {
        transform: translateX(-100%) rotate(45deg);
    }
    40%, 60% {
        transform: translateX(100%) rotate(45deg);
    }
}