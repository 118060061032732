.loading-overlay-local {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный фон */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Оверлей поверх всех элементов */
}

.spinner-local {
    border: 12px solid #f3f3f3;
    border-top: 12px solid #3498db;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}

@keyframes spin-local {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
