@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

.playScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*background: linear-gradient(135deg, #090A0F, #4A0E82);*/
    background: linear-gradient(135deg, #090A0F, #2a064b);
    z-index: 1000;
    font-family: 'Poppins', sans-serif;
    color: #FFFFFF;
}

.version {
    position: absolute;
    top: 10px;
    left: 20px;
    font-size: 0.8em;
    opacity: 0.7;
}

.title {
    font-size: 2.5em;
    font-weight: 700;
    color: #FFFFFF;
    text-shadow: 0 0 10px rgba(138, 43, 226, 0.7);
    margin-bottom: 20px;
    letter-spacing: 2px;
    position: absolute;
    top: 0;
}

.gameTitle {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
}

.image {
    width: 300px;
    height: auto;
    margin-bottom: 30px;
    animation: pulse 2s infinite;
    /*position: absolute;*/
    /*top: 30%;*/
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.loadingBar {
    width: 80%;
    max-width: 300px;
    height: 10px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.loadingProgress {
    width: 0%;
    height: 100%;
    background: linear-gradient(90deg, #8A2BE2, #4A0E82);
    border-radius: 5px;
    transition: width 0.5s ease;
    position: relative;
    overflow: hidden;
}

.loadingProgress::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.4),
            transparent
    );
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

.loadingText {
    margin-top: 10px;
    font-size: 1em;
    font-weight: 600;
    color: #FFFFFF;
    text-shadow: 0 0 5px rgba(138, 43, 226, 0.7);
}

.partnerLogo {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    height: auto;
    opacity: 0.9;
    transition: opacity 0.3s ease;
}

.partnerLogo:hover {
    opacity: 1;
}